/* colorScheme
purple rgb(53,39,72)
green rgb(155,170, 141)
red rgb(195,9,15)
tan rgb(240,179,122)
gray rgb(57,59,62)
*/

/* animated lines */
.absolute {
	position: absolute;
	max-width: 75vw;
	top: -18vh;
	right: 0;
	left: 0;
	z-index: -1;
}

#left-line-container {
	display: flex;
	justify-content: center;
}
#line-container {
	display: flex;
	justify-content: center;
}
#right-line-container {
	display: flex;
	justify-content: center;
}
/* all line styles */
.line {
	width: 10px;
	margin: 0px 10px 0px 10px;
	border-radius: 0px 0px 10px 10px;
	box-shadow: 5px -5px 10px rgba(31, 55, 70, 0.575);
	animation-duration: 5s;
	animation-fill-mode: forwards;
}

/* left 3 line styles */
.left-left-line {
	background-color: rgb(105, 46, 187);
	animation-name: leftOutsideLine;
}
.left-center-line {
	background-color: rgb(155, 170, 141);
	animation-name: leftCenterLine;
}

.left-right-line {
	background-color: rgb(240, 179, 122);
	animation-name: leftInsideLine;
}

/* center 3 line styles */
.left-line {
	background-color: rgb(151, 5, 10);
	animation-name: outsideLine;
}
.center-line {
	background-color: rgb(195, 9, 15);
	animation-name: centerLine;
}

.right-line {
	background-color: rgb(151, 5, 10);
	animation-name: outsideLine;
}

/* right 3 line styles */
.right-left-line {
	background-color: rgb(240, 179, 122);
	animation-name: rightInsideLine;
}
.right-center-line {
	background-color: rgb(155, 170, 141);
	animation-name: rightCenterLine;
}

.right-right-line {
	background-color: rgb(105, 46, 187);
	animation-name: rightOutsideLine;
}

@media only screen and (max-width: 1300px) {
	.absolute {
		max-width: 95vw;
	}
	#left-line-container {
		margin: 0px 0px 0px 30px;
	}

	#right-line-container {
		margin: 0px 30px 0px 0px;
	}
}
@media only screen and (max-width: 900px) {
	.absolute {
		display: none;
	}
}
