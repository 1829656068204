@import url(https://fonts.googleapis.com/css?family=Marcellus+SC&display=swap);
@import url(https://fonts.googleapis.com/css?family=Abel&display=swap);
li > .active {
	color: rgb(250, 255, 246) !important;
	font-size: +110%;
}

.navLink-btn {
	padding: 1rem;
	text-decoration: none;
	font-size: 1rem;
	color: rgba(248, 255, 241, 0.466);
	border-radius: 3px;
}
.active:not(.navLink-title) {
	color: white;
	font-size: +110%;
}
.navLink-btn:hover {
	background-color: rgba(37, 29, 44, 0.171);
	color: rgba(255, 255, 255, 0.781);
	box-shadow: inset 0 0 10px #000000b4;
}

.menuBg {
	-webkit-justify-content: flex-end !important;
	        justify-content: flex-end !important;
}

#navBar {
	background-color: rgb(94, 69, 128);
}

.navLink {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: flex-end;
	        justify-content: flex-end;
}

#primary-search-account-menu-mobile > div > ul {
	background-color: rgb(121, 89, 165);
}

.sectionMobile {
	display: none;
	color: rgb(248, 255, 241);
}

#title > .navLink-title {
	font-family: 'Marcellus SC', serif;
	color: rgb(248, 255, 241);
	text-decoration: none;
}
/* mobile */

.mobile-navLink-btn {
	text-decoration: none;
}

.mobile-navLink-btn > li {
	color: rgb(214, 212, 212);
}
.active > li {
	color: rgb(255, 253, 253);
	font-size: +110%;
}

@media only screen and (max-width: 1300px) {
	.sectionDesktop {
		display: none;
	}

	.sectionMobile {
		display: inline-block;
	}
}

@media only screen and (min-width: 760px) {
	#name {
		display: none;
	}
}

@media only screen and (max-width: 420px) {
	.MuiTypography-h3 > .navLink-title {
		font-size: 2.5rem;
	}
}

#icon-container {
	position: absolute;
	width: 60px;
	background-color: rgb(94, 69, 128);
	border-radius: 0px 10px 10px 0px;
	top: 45vh;
	z-index: 500;
}
#icon-container > div > a > span > i {
	font-size: 2rem;
	color: rgba(248, 255, 241, 0.466);
}

#icon-container > div > a > span > i:hover {
	color: rgba(248, 255, 241, 0.959);
}

@media only screen and (max-width: 1300px) {
	#icon-container {
		display: none;
	}
}

#bottom-mobile-nav {
	display: none;
	font-size: 1.5rem;
	background-color: rgb(109, 122, 96);
	box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
		0px 4px 0px 5px rgba(0, 0, 0, 0.14), 0px 0px 10px 15px rgba(0, 0, 0, 0.12);
}
#bottom-mobile-nav > button,
#bottom-mobile-nav > a > span {
	color: rgba(248, 255, 241, 0.466);
}
#bottom-mobile-nav > button:hover,
#bottom-mobile-nav > a > span:hover {
	color: rgba(248, 255, 241, 0.959);
}
.link-btn > span > i {
	color: rgba(248, 255, 241, 0.466);
}
.link-btn > span > i:hover {
	color: rgba(248, 255, 241, 0.959);
}

@media only screen and (max-width: 1300px) {
	#bottom-mobile-nav {
		display: -webkit-flex;
		display: flex;
		position: absolute;
		height: auto;
		bottom: 0;
		width: 100%;
		z-index: 99;
	}
	.link-btn {
		padding: 0;
	}
	.github {
		color: blue;
		font-size: 1.5rem;
		padding: 0px;
	}

	.linkedIn {
		color: yellow;
		font-size: 1.5rem;
		padding: 0px;
	}

	.contact {
		color: red;
		font-size: 1.5rem;
		padding: 0px;
	}
}

.grid-bg {
	background-color: transparent !important;
}

.gallery {
	list-style: none;
	padding: 15px;
}
.MuiIconButton-root.link-btn {
	font-size: 1.2rem;
}
.MuiGridListTile-tile > a > div > img {
	width: 100%;
}

/* keyframs for center 3 lines */
@-webkit-keyframes centerLine {
	from {
		height: 0vh;
	}

	to {
		height: 95vh;
	}
}
@keyframes centerLine {
	from {
		height: 0vh;
	}

	to {
		height: 95vh;
	}
}

@-webkit-keyframes outsideLine {
	from {
		height: 0vh;
	}

	to {
		height: 93vh;
	}
}

@keyframes outsideLine {
	from {
		height: 0vh;
	}

	to {
		height: 93vh;
	}
}
/* keyframs for left 3 lines */
@-webkit-keyframes leftInsideLine {
	from {
		height: 0vh;
	}

	to {
		height: 91vh;
	}
}
@keyframes leftInsideLine {
	from {
		height: 0vh;
	}

	to {
		height: 91vh;
	}
}
@-webkit-keyframes leftCenterLine {
	from {
		height: 0vh;
	}

	to {
		height: 89vh;
	}
}
@keyframes leftCenterLine {
	from {
		height: 0vh;
	}

	to {
		height: 89vh;
	}
}
@-webkit-keyframes leftOutsideLine {
	from {
		height: 0vh;
	}

	to {
		height: 87vh;
	}
}
@keyframes leftOutsideLine {
	from {
		height: 0vh;
	}

	to {
		height: 87vh;
	}
}
/* keyframs for left 3 lines */
@-webkit-keyframes rightInsideLine {
	from {
		height: 0vh;
	}

	to {
		height: 91vh;
	}
}
@keyframes rightInsideLine {
	from {
		height: 0vh;
	}

	to {
		height: 91vh;
	}
}

@-webkit-keyframes rightCenterLine {
	from {
		height: 0vh;
	}

	to {
		height: 89vh;
	}
}

@keyframes rightCenterLine {
	from {
		height: 0vh;
	}

	to {
		height: 89vh;
	}
}

@-webkit-keyframes rightOutsideLine {
	from {
		height: 0vh;
	}

	to {
		height: 87vh;
	}
}

@keyframes rightOutsideLine {
	from {
		height: 0vh;
	}

	to {
		height: 87vh;
	}
}
/* colorScheme
purple rgb(53,39,72)
green rgb(155,170, 141)
red rgb(195,9,15)
tan rgb(240,179,122)
gray rgb(57,59,62)
*/

/* animated lines */
.absolute {
	position: absolute;
	max-width: 75vw;
	top: -18vh;
	right: 0;
	left: 0;
	z-index: -1;
}

#left-line-container {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
}
#line-container {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
}
#right-line-container {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
}
/* all line styles */
.line {
	width: 10px;
	margin: 0px 10px 0px 10px;
	border-radius: 0px 0px 10px 10px;
	box-shadow: 5px -5px 10px rgba(31, 55, 70, 0.575);
	-webkit-animation-duration: 5s;
	        animation-duration: 5s;
	-webkit-animation-fill-mode: forwards;
	        animation-fill-mode: forwards;
}

/* left 3 line styles */
.left-left-line {
	background-color: rgb(105, 46, 187);
	-webkit-animation-name: leftOutsideLine;
	        animation-name: leftOutsideLine;
}
.left-center-line {
	background-color: rgb(155, 170, 141);
	-webkit-animation-name: leftCenterLine;
	        animation-name: leftCenterLine;
}

.left-right-line {
	background-color: rgb(240, 179, 122);
	-webkit-animation-name: leftInsideLine;
	        animation-name: leftInsideLine;
}

/* center 3 line styles */
.left-line {
	background-color: rgb(151, 5, 10);
	-webkit-animation-name: outsideLine;
	        animation-name: outsideLine;
}
.center-line {
	background-color: rgb(195, 9, 15);
	-webkit-animation-name: centerLine;
	        animation-name: centerLine;
}

.right-line {
	background-color: rgb(151, 5, 10);
	-webkit-animation-name: outsideLine;
	        animation-name: outsideLine;
}

/* right 3 line styles */
.right-left-line {
	background-color: rgb(240, 179, 122);
	-webkit-animation-name: rightInsideLine;
	        animation-name: rightInsideLine;
}
.right-center-line {
	background-color: rgb(155, 170, 141);
	-webkit-animation-name: rightCenterLine;
	        animation-name: rightCenterLine;
}

.right-right-line {
	background-color: rgb(105, 46, 187);
	-webkit-animation-name: rightOutsideLine;
	        animation-name: rightOutsideLine;
}

@media only screen and (max-width: 1300px) {
	.absolute {
		max-width: 95vw;
	}
	#left-line-container {
		margin: 0px 0px 0px 30px;
	}

	#right-line-container {
		margin: 0px 30px 0px 0px;
	}
}
@media only screen and (max-width: 900px) {
	.absolute {
		display: none;
	}
}

html {
	overflow: auto;
}

#wrapper {
	position: relative;
	margin: 8rem auto;
	padding: 5px;
	max-width: 75vw;
	padding-bottom: 3.5rem;
}

#wrapper > div > ul.griddy {
	margin-bottom: 5rem !important;
}
.lazyGrid > div > img {
	height: auto;
}

@media only screen and (max-width: 1024px) {
	#wrapper {
		margin: 6rem auto;
		margin-bottom: 2rem;
	}
}

@media only screen and (max-width: 768px) {
	#wrapper {
		margin: 5rem auto;
		margin-top: 2rem;
		max-width: 95vw;
	}
}
@media only screen and (max-width: 420px) {
	#wrapper {
		margin: 5rem auto;
		margin-top: 2rem;
		min-width: 100vw;
		padding: 0px;
		padding-bottom: 8.5rem;
	}
}

html {
	overflow: auto;
}

#wrapper {
	position: relative;
	margin: 8rem auto;
	padding: 5px;
	max-width: 75vw;
	padding-bottom: 3.5rem;
}

#wrapper > div > ul.griddy {
	margin-bottom: 5rem !important;
}
.lazyGrid > div > img {
	height: auto;
}

@media only screen and (max-width: 1024px) {
	#wrapper {
		margin: 6rem auto;
		margin-bottom: 2rem;
	}
}

@media only screen and (max-width: 768px) {
	#wrapper {
		margin: 5rem auto;
		margin-top: 2rem;
		max-width: 95vw;
	}
}
@media only screen and (max-width: 420px) {
	#wrapper {
		margin: 5rem auto;
		margin-top: 2rem;
		min-width: 100vw;
		padding: 0px;
		padding-bottom: 8.5rem;
	}
}

.img-loading {
	opacity: 0;
	width: 100%;
	height: auto;
}
.img-loaded {
	-webkit-animation: fadeInImg cubic-bezier(0.23, 1, 0.32, 1) 1;
	        animation: fadeInImg cubic-bezier(0.23, 1, 0.32, 1) 1;
	position: relative;
	opacity: 0;
	-webkit-animation-fill-mode: forwards;
	        animation-fill-mode: forwards;
	-webkit-animation-duration: 0.7s;
	        animation-duration: 0.7s;
	-webkit-animation-delay: 0.1s;
	        animation-delay: 0.1s;
}

@-webkit-keyframes fadeInImg {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes fadeInImg {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

body,
html {
	height: 100%;
}
#brand {
	display: -webkit-flex;
	display: flex;
	text-align: right;
	-webkit-flex-direction: column;
	        flex-direction: column;
	padding-top: 40vh;
	padding-bottom: 0px;
	margin-right: 15vw;
	font-family: 'Marcellus SC', serif;
}

.name {
	z-index: 100;
	color: rgb(233, 233, 233);
	font-size: 12rem;
}

.title {
	z-index: 100;
	font-size: 2rem;
	color: rgb(233, 233, 233);
}

#mainImg {
	position: relative;
	width: 100%;
}

#mainImg > div > img {
	position: absolute;
	height: calc(100vh - 136px);
	min-height: calc(100vh - 64px);
	width: 100%;
	background-position: 50% 50%;
	background-position: center;
	background-repeat: no-repeat;
	background: rgb(80, 138, 83);
	-webkit-object-fit: cover;
	-moz-object-fit: cover;
	object-fit: cover;
}

@media only screen and (max-width: 1300px) {
	.name {
		color: white;
		font-size: 8rem;
	}
}

@media only screen and (max-width: 760px) {
	.name {
		display: none;
	}
	.title {
		display: none;
	}
}


#FourOFour {
	-webkit-align-items: center;
	        align-items: center;
	background-color: rgba(255, 255, 255, 0.63);
	border: 5px rgba(94, 69, 128, 0.842) solid;
	border-radius: 10px;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	height: 50%;
	left: 50%;
	-webkit-justify-content: center;
	        justify-content: center;
	margin-left: -40%; /*half the size of width*/
	position: absolute;
	width: 80%;
	top: 25%;
	z-index: 99;
}

p {
	font-size: 6rem;
	margin: 2rem;
	color: rgba(63, 63, 63, 0.897);
}

#FoFText {
	font-size: 1.8rem;
	margin-bottom: 3rem;
}

.FoFWrapper {
	min-width: 100vw;
	min-height: 100vh;
	background-color: rgba(195, 188, 199, 0.445);
}

#return-button {
	width: 30vw;
	padding: 1rem;
	background-color: rgba(109, 122, 96, 0.438);
	border: 1px solid rgb(109, 122, 96);
	border-radius: 5px;
	color: rgba(48, 47, 47, 0.774);
	text-decoration: none;
	text-align: center;
}

#return-button:hover {
	background-color: rgba(109, 122, 96, 0.904);
	border: 1px solid rgb(48, 58, 38);
	color: rgba(243, 241, 241, 0.952);
}

html {
	overflow: visible;
}
body {
	margin: 0;
	padding: 0;
	background-color: rgb(245, 245, 245);
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
#root {
	position: relative;
	min-height: 100%;
}
.gallery > div > div > div > div {
	color: rgba(242, 243, 242, 0.931);
}

.gallery > div > div > div > div > div > a > span {
	color: rgba(242, 243, 242, 0.822);
}

.gallery > div > div > div > div > div > a > span:hover {
	color: rgba(248, 255, 241, 0.959);
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

/* colorScheme
purple rgb(53,39,72)
green rgb(155,170, 141)
red rgb(195,9,15)
tan rgb(240,179,122)
gray rgb(57,59,62)
*/

