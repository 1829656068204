html {
	overflow: auto;
}

#wrapper {
	position: relative;
	margin: 8rem auto;
	padding: 5px;
	max-width: 75vw;
	padding-bottom: 3.5rem;
}

#wrapper > div > ul.griddy {
	margin-bottom: 5rem !important;
}
.lazyGrid > div > img {
	height: auto;
}

@media only screen and (max-width: 1024px) {
	#wrapper {
		margin: 6rem auto;
		margin-bottom: 2rem;
	}
}

@media only screen and (max-width: 768px) {
	#wrapper {
		margin: 5rem auto;
		margin-top: 2rem;
		max-width: 95vw;
	}
}
@media only screen and (max-width: 420px) {
	#wrapper {
		margin: 5rem auto;
		margin-top: 2rem;
		min-width: 100vw;
		padding: 0px;
		padding-bottom: 8.5rem;
	}
}
