html {
	overflow: visible;
}
body {
	margin: 0;
	padding: 0;
	background-color: rgb(245, 245, 245);
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
#root {
	position: relative;
	min-height: 100%;
}
.gallery > div > div > div > div {
	color: rgba(242, 243, 242, 0.931);
}

.gallery > div > div > div > div > div > a > span {
	color: rgba(242, 243, 242, 0.822);
}

.gallery > div > div > div > div > div > a > span:hover {
	color: rgba(248, 255, 241, 0.959);
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

@import url('https://fonts.googleapis.com/css?family=Marcellus+SC&display=swap');
@import url('https://fonts.googleapis.com/css?family=Abel&display=swap');

/* colorScheme
purple rgb(53,39,72)
green rgb(155,170, 141)
red rgb(195,9,15)
tan rgb(240,179,122)
gray rgb(57,59,62)
*/
