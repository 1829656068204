#bottom-mobile-nav {
	display: none;
	font-size: 1.5rem;
	background-color: rgb(109, 122, 96);
	box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
		0px 4px 0px 5px rgba(0, 0, 0, 0.14), 0px 0px 10px 15px rgba(0, 0, 0, 0.12);
}
#bottom-mobile-nav > button,
#bottom-mobile-nav > a > span {
	color: rgba(248, 255, 241, 0.466);
}
#bottom-mobile-nav > button:hover,
#bottom-mobile-nav > a > span:hover {
	color: rgba(248, 255, 241, 0.959);
}
.link-btn > span > i {
	color: rgba(248, 255, 241, 0.466);
}
.link-btn > span > i:hover {
	color: rgba(248, 255, 241, 0.959);
}

@media only screen and (max-width: 1300px) {
	#bottom-mobile-nav {
		display: flex;
		position: absolute;
		height: auto;
		bottom: 0;
		width: 100%;
		z-index: 99;
	}
	.link-btn {
		padding: 0;
	}
	.github {
		color: blue;
		font-size: 1.5rem;
		padding: 0px;
	}

	.linkedIn {
		color: yellow;
		font-size: 1.5rem;
		padding: 0px;
	}

	.contact {
		color: red;
		font-size: 1.5rem;
		padding: 0px;
	}
}
