body,
html {
	height: 100%;
}
#brand {
	display: flex;
	text-align: right;
	flex-direction: column;
	padding-top: 40vh;
	padding-bottom: 0px;
	margin-right: 15vw;
	font-family: 'Marcellus SC', serif;
}

.name {
	z-index: 100;
	color: rgb(233, 233, 233);
	font-size: 12rem;
}

.title {
	z-index: 100;
	font-size: 2rem;
	color: rgb(233, 233, 233);
}

#mainImg {
	position: relative;
	width: 100%;
}

#mainImg > div > img {
	position: absolute;
	height: calc(100vh - 136px);
	min-height: calc(100vh - 64px);
	width: 100%;
	background-position: 50% 50%;
	background-position: center;
	background-repeat: no-repeat;
	background: rgb(80, 138, 83);
	-webkit-object-fit: cover;
	-moz-object-fit: cover;
	-o-object-fit: cover;
	object-fit: cover;
}

@media only screen and (max-width: 1300px) {
	.name {
		color: white;
		font-size: 8rem;
	}
}

@media only screen and (max-width: 760px) {
	.name {
		display: none;
	}
	.title {
		display: none;
	}
}
