.grid-bg {
	background-color: transparent !important;
}

.gallery {
	list-style: none;
	padding: 15px;
}
.MuiIconButton-root.link-btn {
	font-size: 1.2rem;
}
.MuiGridListTile-tile > a > div > img {
	width: 100%;
}
