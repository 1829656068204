/* keyframs for center 3 lines */
@keyframes centerLine {
	from {
		height: 0vh;
	}

	to {
		height: 95vh;
	}
}

@keyframes outsideLine {
	from {
		height: 0vh;
	}

	to {
		height: 93vh;
	}
}
/* keyframs for left 3 lines */
@keyframes leftInsideLine {
	from {
		height: 0vh;
	}

	to {
		height: 91vh;
	}
}
@keyframes leftCenterLine {
	from {
		height: 0vh;
	}

	to {
		height: 89vh;
	}
}
@keyframes leftOutsideLine {
	from {
		height: 0vh;
	}

	to {
		height: 87vh;
	}
}
/* keyframs for left 3 lines */
@keyframes rightInsideLine {
	from {
		height: 0vh;
	}

	to {
		height: 91vh;
	}
}

@keyframes rightCenterLine {
	from {
		height: 0vh;
	}

	to {
		height: 89vh;
	}
}

@keyframes rightOutsideLine {
	from {
		height: 0vh;
	}

	to {
		height: 87vh;
	}
}